<template>
  <div class="cash-request-list">
    <error :errors="walletError" />
    <b-overlay :show="formBusy" rounded="sm">
      <paginate :meta="cashRequestMeta" :implement-search="false" :refreshable="false" @change-page="onChangePage" @refresh="onRefresh">
        <template slot="action">
          <b-input-group class="d-none d-sm-flex">
            <datetime placeholder="باشلىنىش ۋاقتىنى تاللاڭ" id="start_time" v-model="formData.start_time" class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
            <b-input-group-prepend is-text><i class="fa fa-long-arrow-alt-left"></i></b-input-group-prepend>
            <datetime placeholder="ئاياقلىشىش ۋاقتىنى تاللاڭ" id="end_time" v-model="formData.end_time" class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
            <b-input-group-append>
              <b-button size="sm" :disabled="!formData.start_time || !formData.end_time" @click="onChangeTime" text="Button" variant="success">يېڭىلاش</b-button>
            </b-input-group-append>
          </b-input-group>
        </template>
        <template slot="foot">
          <b-row class="row-no-padding row-col-separator-lg">
            <b-col sm="12" md="4">
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">قالدۇق سومما</h4>
                    <span class="kt-widget24__desc">بارلىق ئەزالارنىڭ نەقلەشتۈرۈشكە بولىدىغان قالدۇق سوممىسى</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-brand">{{ all_wallet_remainder }} يۈەن</span>
                </div>
                <b-progress size="sm">
                  <b-progress-bar :value="remainder_progress" variant="primary"></b-progress-bar>
                </b-progress>
                <div class="kt-widget24__action">
                  <span class="kt-widget24__change">ئۇمۇمىي كىرىم</span>
                  <span class="kt-widget24__number">{{ all_wallet_income }} يۈەن</span>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="4">
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">نەقلەشتۈرگىنى</h4>
                    <span class="kt-widget24__desc">بارلىق ئەزالارنىڭ ھازىرغىچە نەقلەشتۈرگەن سوممىسى</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-success">{{ total_cashing }} يۈەن</span>
                </div>
                <b-progress size="sm">
                  <b-progress-bar :value="cashing_progress" variant="success"></b-progress-bar>
                </b-progress>
                <div class="kt-widget24__action">
                  <span class="kt-widget24__change">ئۇمۇمىي كىرىم</span>
                  <span class="kt-widget24__number">{{ all_wallet_income }} يۈەن</span>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="4">
              <div class="kt-widget24">
                <div class="kt-widget24__details">
                  <div class="kt-widget24__info">
                    <h4 class="kt-widget24__title">ئۇمۇمىي كىرىم</h4>
                    <span class="kt-widget24__desc">بارلىق ئەزالارنىڭ ھازىرغىچە قىلغان كىرىمى</span>
                  </div>
                  <span class="kt-widget24__stats kt-font-danger">{{ all_wallet_income }} يۈەن</span>
                </div>
                <b-progress size="sm">
                  <b-progress-bar :value="100" variant="danger"></b-progress-bar>
                </b-progress>
                <div class="kt-widget24__action">
                  <span class="kt-widget24__change">ئۇمۇمىي كىرىم ۋە قالدۇق سومما ۋاقىت ئامىلىنىڭ تەسىرىگە ئۇچرىمايدۇ</span>
                  <span class="kt-widget24__number"></span>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="row-no-padding row-col-separator-lg">
            <b-col sm="12" :md="4">
              <!--begin:: Widgets/Stats2-1 -->
              <div class="kt-widget1 pt-0">
                <div class="kt-widget1__item ">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">كېلىشىم ئەزا</h3>
                    <span class="kt-widget1__desc">كېلىشىم ئەزاغا بېرىلگەن مۇكاپات سوممىسى</span>
                  </div>
                  <span class="kt-widget1__number kt-font-brand">{{contract_reward || '0.00'}}</span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">ئادەتتىكى ئەزالىق</h3>
                    <span class="kt-widget1__desc">ئادەتتىكى ئەزالىقتىن ئايرىلغان پايدا سوممىسى</span>
                  </div>
                  <span class="kt-widget1__number kt-font-success">{{membership_reward || '0.00'}}</span>
                </div>
              </div>
              <!--end:: Widgets/Stats2-1 -->
            </b-col>
            <b-col sm="12" :md="4">
              <!--begin:: Widgets/Stats2-1 -->
              <div class="kt-widget1 pt-0">
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">يېڭى ئەزا</h3>
                    <span class="kt-widget1__desc">يېڭى ئەزاغا بېرىلگەن مۇكاپات سوممىسى</span>
                  </div>
                  <span class="kt-widget1__number kt-font-brand">{{invitor_reward || '0.00'}}</span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">فىلىم سېتىۋېلىش</h3>
                    <span class="kt-widget1__desc">سېتىلغان فىلىمدىن ئايرىلغان پايدا سوممىسى</span>
                  </div>
                  <span class="kt-widget1__number kt-font-danger">{{film_reward || '0.00'}}</span>
                </div>
              </div>
              <!--end:: Widgets/Stats2-1 -->
            </b-col>
            <b-col sm="12" :md="4">
              <!--begin:: Widgets/Stats2-1 -->
              <div class="kt-widget1 pt-0">
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">ئېلان</h3>
                    <span class="kt-widget1__desc">ئېلان چىكىش مۇكاپات سوممىسى</span>
                  </div>
                  <span class="kt-widget1__number kt-font-brand">{{ad_click_reward || '0.00'}}</span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">ئىلتىماس قىلغىنى</h3>
                    <span class="kt-widget1__desc">نەقلەشتۈرۈشنى ساقلاۋاتقان ئۇمۇمىي سومما</span>
                  </div>
                  <span class="kt-widget1__number kt-font-danger">{{pending_cashing || '0.00'}}</span>
                </div>
              </div>
              <!--end:: Widgets/Stats2-1 -->
            </b-col>
          </b-row>
        </template>
      </paginate>
      <b-row>
        <b-col v-for="item in cashRequestList" md="3" :key="item.id" sm="6">
          <portlet title=" " body-fluid body-class="px-3 pb-3 pt-0" headNoBorder head-class="px-2" head-size="sm">
            <template v-slot:body>
              <div class="kt-widget-item-container">
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__head" style="margin-top: -35px;">
                  <div class="kt-widget__media">
                    <b-avatar text="!" :src="item.wallet.user ? item.wallet.user.avatar : ''" />
                  </div>
                  <div class="kt-widget__info overflow-hidden">
                    <a class="kt-widget__username">
                      {{  item.wallet.user ? item.wallet.user.nickname : 'نامەلۇم' }}
                    </a>
                  </div>
                </div>
                <div class="kt-widget__body">
                  <div class="kt-widget__item">
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">ھالىتى</span>
                      <span class="kt-widget__data" v-if="item.status === 'reject'">رەت قىلىنغان</span>
                      <span class="kt-widget__data text-success" v-if="item.status === 'success'"> تەستىقلانغان </span>
                      <span class="kt-widget__data text-danger" v-if="item.status === 'pending'"> تەستىق كۈتىۋاتىدۇ </span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">ئىلتىماس قىلغان سوممىسى</span>
                      <span class="kt-widget__data">{{ item.total }} ￥</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">ھېساباتىدىكى قالدۇق سومما</span>
                      <span class="kt-widget__data">{{ item.wallet.remainder }} ￥</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">ئىلتىماس قىلغان ۋاقتى</span>
                      <span class="kt-widget__data" style="direction: ltr">{{ item.created_at }}</span>
                    </div>
                  </div>
                </div>
                <div class="kt-widget__footer d-flex justify-content-around">
                  <b-button v-if="item.wallet.user" :to="{name: 'wallet.show', params:{user_id: item.wallet.user.id}}" type="button" variant="primary" class="btn-upper mr-2"> كىرىم چىقىم تەپسىلاتى </b-button>
                  <b-button v-if="item.status === 'pending'" @click="handleCashRequest(item)" type="button" variant="danger" class="btn-upper ml-2"> تەستىقلاش </b-button>
                </div>
              </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <no-result :visible="!cashRequestList.length" background="warning" title="ئۇچۇر تېپىلمىدى" desc="مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، نەقلەشتۈرۈش ئىلتىماس قىلغانلار يوق ئىكەن." >
        <template slot="action">
          <b-button :to="{name: 'user.home'}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-warning">ئەزا باشقۇرۇش</b-button>
        </template>
      </no-result>
    </b-overlay>
    <handle-request
      :option="currentItem"
      v-model="cashRequestModalState"
      @confirm="onRequestConfirm"
      @failed="onRequestFailed"
    ></handle-request>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import Error from "@v@/partials/widgets/Error";
  import paginate from "@v@/components/paginate";
  import NoResult from "@v@/partials/content/NoResult";
  import HandleCashRequestModal from "@v@/components/modals/wallet.handleCashRequest.modal";
  import walletMixin from "@m@/wallet.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import 'vue-datetime/dist/vue-datetime.css'
  import { Datetime } from "vue-datetime";
  export default {
    name: "index",
    components: { Error, Portlet, 'handle-request': HandleCashRequestModal, paginate, NoResult, Datetime },
    mixins: [ walletMixin, formBusyMixin ],
    created() {
      this.getCashRequestList();
      this.getCashStatistics();
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'ئەزا باشقۇرۇش', route: {name: 'user.home'}});
      BreadCrumbs.addBreadCrumb({ title: "نەقلەشتۈرۈش ئىلتىماسى" });
    },
    computed: {
      all_wallet_income(){
        return this.cashRequestStatistics.all_wallet_income;
      },
      all_wallet_remainder(){
        return this.cashRequestStatistics.all_wallet_remainder;
      },
      total_cashing(){
        return this.cashRequestStatistics.total_cashing;
      },
      remainder_progress(){
        return (this.all_wallet_remainder / this.all_wallet_income) * 100;
      },
      cashing_progress(){
        return (this.total_cashing / this.all_wallet_income) * 100;
      },
      contract_reward(){
        return this.cashRequestStatistics.contract_reward;
      },
      membership_reward(){
        return this.cashRequestStatistics.membership_reward;
      },
      invitor_reward(){
        return this.cashRequestStatistics.invitor_reward;
      },
      film_reward(){
        return this.cashRequestStatistics.film_reward;
      },
      ad_click_reward(){
        return this.cashRequestStatistics.ad_click_reward;
      },
      pending_cashing(){
        return this.cashRequestStatistics.pending_cashing;
      },
    },
    data(){
      return {
        formBusy: false,
        page: 1,
        cashRequestModalState: false,
        currentItem: {},
        filterOption: [
          { text: 'ھەممىسى', value: 0 },
          { text: 'تەستىق كۈتىۋاتقانلىرى', value: 1 },
        ],
        filter: 0,
        formData: {
          start_time: '',
          end_time: ''
        },
      };
    },
    methods: {
      onChangeTime(){
        this.getCashRequestList(this.filter, 1, this.formData);
        this.getCashStatistics(this.formData);
      },
      onChangePage(e){
        let { page, title } = e;
        this.getCashRequestList(this.filter, page, this.formData);
      },
      onRefresh(){
        this.getCashRequestList(this.filter, 1);
        this.getCashStatistics(this.formData);
      },
      setModalState(state = true){
        this.cashRequestModalState = state;
      },
      handleCashRequest(item){
        this.currentItem = item;
        this.$nextTick(()=>{
          this.setModalState();
        })
      },
      onRequestConfirm(){
        this.setModalState(false);
        this.getCashRequestList(this.filter, this.page);
      },
      onRequestFailed(){
        this.setModalState(false);
      },
    }
  }
</script>

<style>

  .vdatetime-input {
    width: 100%;
    height: 100%;
    border: unset;
  }

  .kt-widget__head .kt-widget__info {
    overflow: hidden;
  }

  .kt-widget__section {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    height: 74px;
  }

  .kt-widget__data {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .kt-widget__username {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: justify;
    display: block;
    width: 100%;
  }
</style>
