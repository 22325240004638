<template>
  <div class="cash-request">
    <base-modal :id="id" :title="title" :modal-busy="formBusy" :size="size" v-model="modalState" >
        <p class="total-money my-6 text-danger"> {{ option.total }} يۈەن </p>
        <b-row>
          <b-col cols="6">
            <b-form-group id="input-group-status" label="تەستىقلامسىز ؟" label-for="status">
              <b-form-radio-group
                id="status"
                v-model="formData.status"
                :options="[{text: 'تەستىقلاش', value: 'success'}, {text: 'نەقلەشتۈرۈلدى', value: 'completed'} ,{text: 'رەت قىلىش', value: 'reject'}]"
                buttons
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="formData.status === 'success'">
            <b-form-group id="input-group-status" label="ئەسكەرتىش">
              <b-form-text>بىۋاستە ئۈندىداردا چىقىمدان ھېساباتى ئارقىلىق ئەزانىڭ ئۈندىدار چىقىمدانىغا نەقلەشتۈرۈش ئۈچۈن، مەزكۇر تاللانمىنى تاللاپ جەزىملەشتۈرىۋەتسىڭىز بولىدۇ</b-form-text>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="formData.status === 'completed'">
            <b-form-group id="input-group-status" label="ئەسكەرتىش">
              <b-form-text>بىۋاستە ئۈندىداردا نەقلەشتۈرۈپ بولغان نەقلەشتۈرۈش ئىلتىماسىغا قارىتا، مەزكۇر تاللانمىنى تاللاپ جەزىملەشتۈرىۋەتسىڭىز بولىدۇ</b-form-text>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group v-if="formData.status === 'reject'" id="input-group-description" label="رەت قىلىش سەۋەبى" label-for="description">
          <b-input-group>
            <b-form-input id="description" v-model="formData.description" placeholder="رەت قىلىش سەۋەبىنى كىرگۈزۈڭ"></b-form-input>
            <b-input-group-append>
              <b-button @click="onConfirm" size="sm" variant="primary">جەزىملەش</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-button @click="onConfirm" v-if="['success', 'completed'].includes(formData.status)"  id="submit" type="button" variant="primary">
          جەزىملەش
        </b-button>
    </base-modal>
  </div>
</template>

<script>
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import walletMixin from "@m@/wallet.mixin";
  import baseModal from "@v@/components/modals/base.modal";
  export default {
    name: "HandleCashRequestModal",
    components: { baseModal },
    mixins: [ formBusyMixin, walletMixin ],
    props: {
      id: {
        type: String,
        default: () => 'wallet-handle-cash-request-modal'
      },
      value: {
        type: Boolean,
      },
      title: {
        type: String,
        default: () => 'نەقلەشتۈرۈش ئىلتىماسى بىر تەرەپ قىلىش'
      },
      size: {
        type: String,
        default: ()=> 'lg'
      },
      option: {
        type: Object,
        default: ()=>{return {};}
      }
    },
    computed: {
      modalState: {
        get(){
          return this.value;
        },
        set(v){
          this.$emit('input', v);
        },
      },
    },
    data(){
      return {
        formBusy: false,
        formData: {
          description: '',
          status: null
        }
      };
    },
    methods: {
      onConfirm(){
        let formData = Object.assign(this.formData, {id: this.option.id});
        this.handleConfirmCashRequestApi(formData)
          .then(()=>{
            this.$emit('confirm');
          })
          .catch(()=>{
            this.$emit('failed');
            this.hideBusy();
          })
      },
    }
  }
</script>

<style scoped>
  .total-money {
    font-size: 32px;
    text-align: center;
  }
</style>
